<template>
  <div class="chart-container">
    <div class="chart-filter-container">
      <div class="chart-filter-text">
        <div>
          欢迎来到服了吗自助洗车经营系统
        </div>
      </div>
      <div>
        <el-select v-model="bid" placeholder="请选择">
          <el-option
            v-for="item in business_id"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="value"
          size="large"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy 年 MM 月 dd 日"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="success" size="medium" @click="handlePickerSubmit"
          >查询</el-button
        >
      </div>
    </div>
    <el-row :gutter="20" class="el-row-item">
      <el-col :span="5">
        <div class="count-item-container">
          <count-to-num
            :title="'卡券销售额'"
            :totitle="'今日卡券销售额'"
            :endVal="dataStatistics.order_price.order_price_data"
            :toendVal="dataStatistics.order_price.order_price_todata"
            :time="timePickerData"
            :totime="totimePickerData"
            :remarks="'统计服了吗小程序的实际到微信账户的销售额，仅限微信支付，不包含抖音和美团。'"
            :suffix="'元'"
          ></count-to-num>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="count-item-container">
          <count-to-num
            :title="'抖音美团核销金额'"
            :totitle="'今日抖音美团核销金额'"
            :endVal="dataStatistics.douyincounp_number.towel_cdouyin_data_sum"
            :toendVal="dataStatistics.douyincounp_number.towel_cdouyin_todata_sum"
            :time="timePickerData"
            :totime="totimePickerData"
            :remarks="'统计抖音核销和美团发卡的金额，根据卡的面值进行统计。'"
            :suffix="'元'"
          ></count-to-num>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="count-item-container">
          <count-to-num
            :title="'洗车销售额'"
            :totitle="'今日洗车销售额'"
            :endVal="dataStatistics.amount_price.amount_price_data"
            :toendVal="dataStatistics.amount_price.amount_price_todata"
            :time="timePickerData"
            :totime="totimePickerData"
            :remarks="'洗车价格综合，包含服了吗、抖音、美团价格平台卡洗车总和'"
            :suffix="'元'"
          ></count-to-num>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="count-item-container">
          <count-to-num
            :title="'洗车数量'"
            :totitle="'今日洗车数量'"
            :endVal="dataStatistics.car_number.car_number_data"
            :toendVal="dataStatistics.car_number.car_numbere_todata"
            :time="timePickerData"
            :totime="totimePickerData"
            :remarks="'洗车数量总和。'"
            :suffix="'辆'"
          ></count-to-num>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="count-item-container">
          <count-to-num
            :title="'毛巾借用数量'"
            :totitle="'今日毛巾借用数量'"
            :endVal="dataStatistics.towel_number.towel_number_data"
            :toendVal="dataStatistics.towel_number.towel_number_todata"
            :time="timePickerData"
            :totime="totimePickerData"
            :remarks="''"
            :suffix="'条'"
          ></count-to-num>
        </div>
      </el-col>
      
    </el-row>
    <el-row :gutter="20" class="el-row-item">
       
    </el-row>
    <el-row :gutter="20" class="el-row-item">
      <el-col :span="18">
        <div class="chart-item-container">
          <order-lanudry-num ref="orderLanudryNum" :time="timePickerData"></order-lanudry-num>
        </div>
      </el-col>
      <el-col :span="6">
        <el-col
          :span="24"
          class="count-item-container_padding count-item-container_padding-right"
        >
          <div class="count-item-container">
            <count-to-num
              :title="'办卡数量'"
              :totitle="'今日办卡数量'"
              :endVal="dataStatistics.coupon_number.coupon_data"
              :toendVal="dataStatistics.coupon_number.coupon_todata"
              :time="timePickerData"
              :totime="totimePickerData"
              :remarks="'统计服了吗小程序的实际办卡数量，不包含抖音 核销和美团发卡。'"
              :suffix="'张'"
            ></count-to-num>
          </div>
        </el-col>
        <el-col
          :span="24"
          class="count-item-container_padding count-item-container_padding-right"
        >
          <div class="count-item-container count-item-container_margin">
            <count-to-num
              :title="'新增人数'"
              :totitle="'今日新增人数'"
              :endVal="dataStatistics.user_number.user_number_data"
              :toendVal="dataStatistics.user_number.user_number_todata"
              :time="timePickerData"
              :totime="totimePickerData"
              :remarks="'同一个人，在多个门店洗车，只会统计到第一次洗车门店。'"
              :suffix="'个'"
            ></count-to-num>
          </div>
        </el-col>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="el-row-item">
      <el-col :span="12">
        <div class="chart-item-container">
          <count-coupon-num ref="countCouponNum" :time="timePickerData"> </count-coupon-num>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="chart-item-container">
          <count-douyin-count ref="countDouyinCount" :time="timePickerData"> </count-douyin-count>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="el-row-item">
      <el-col :span="12">
        <div class="chart-item-container">
          <count-card-num ref="countCardNum" :time="timePickerData"> </count-card-num>
        </div>
      </el-col>
      <el-col :span="12">
        <div class="chart-item-container">
          <count-time-card-num ref="countTimeCardNum" :time="timePickerData"> </count-time-card-num>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="el-row-item">
      <el-col :span="6">
        <div class="chart-item-container">
          <count-card-count ref="countCardCount" :time="timePickerData"> </count-card-count>
        </div>
      </el-col>
      <el-col :span="6">
        <el-col
          :span="24"
          class="count-item-container_padding count-item-container_padding-right"
        >
          <div class="count-item-container">
            <count-to-num
              :title="'门店小程序卡券分配利润'"
              :totitle="'今日门店小程序卡券分配利润'"
              :endVal="dataLaundry.laundry_business_sum"
              :toendVal="dataLaundry.laundry_business_to_sum"
              :time="timePickerData"
              :totime="totimePickerData"
              :remarks="'统计在服了吗平台购买的卡券，产生的分配比例。'"
              :suffix="'元'"
            ></count-to-num>
          </div>
        </el-col>
        <el-col
          :span="24"
          class="count-item-container_padding count-item-container_padding-right"
        >
          <div class="count-item-container count-item-container_margin">
            <count-to-num
              :title="'门店外部卡券分配利润'"
              :totitle="'今日门店外部卡券分配利润'"
              :endVal="dataLaundry.laundry_business_open_sum"
              :toendVal="dataLaundry.laundry_business_open_to_sum"
              :time="timePickerData"
              :totime="totimePickerData"
              :remarks="'抖音核销、美团卡、人工发卡等'"
              :suffix="'元'"
            ></count-to-num>
          </div>
        </el-col>
      </el-col>
      <el-col :span="6">
        <el-col
          :span="24"
          class="count-item-container_padding count-item-container_padding-right"
        >
          <div class="count-item-container">
            <count-to-num
              :title="'平台卡券分配利润'"
              :totitle="'今日平台卡券分配利润'"
              :endVal="dataLaundry.laundry_web_sum"
              :toendVal="dataLaundry.laundry_web_to_sum"
              :time="timePickerData"
              :totime="totimePickerData"
              :remarks="'统计在服了吗平台购买的卡券，产生的分配比例。'"
              :suffix="'元'"
            ></count-to-num>
          </div>
        </el-col>
        <el-col
          :span="24"
          class="count-item-container_padding count-item-container_padding-right"
        >
          <div class="count-item-container count-item-container_margin">
            <count-to-num
              :title="'平台外部卡券分配利润'"
              :totitle="'今日平台外部卡券分配利润'"
              :endVal="dataLaundry.laundry_web_open_sum"
              :toendVal="dataLaundry.laundry_web_open_to_sum"
              :time="timePickerData"
              :totime="totimePickerData"
              :remarks="'抖音核销、美团卡、人工发卡等'"
              :suffix="'元'"
            ></count-to-num>
          </div>
        </el-col>
       
      </el-col>
      <el-col :span="6">
        <div class="chart-item-container">
          <cost-all ref="costAll" :time="timePickerData"> </cost-all>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="登录"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :show-close="false"
      :fullscreen="true"
      center
    >
      <el-form inline style="text-align: center">
        <el-form-item label="账号">
          <el-input
            type="text"
            placeholder="请输入"
            v-model="username"
            @keyup.enter.native="handleSubmit"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
            type="password"
            placeholder="请输入密码"
            v-model="password"
            @keyup.enter.native="handleSubmit"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSubmit">登 录</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import countToNum from "./components/countToNum/index.vue";
import countToNumOne from "./components/countToNumOne/index.vue";
import orderLanudryNum from "./components/orderLanudryNum/index.vue";
import countCouponNum from "./components/countCouponNum/index.vue";
import countCardNum   from "./components/countCardNum/index.vue";
import countTimeCardNum   from "./components/countTimeCardNum/index.vue";
import countCardCount   from "./components/countCardCount/index.vue";
import costAll  from "./components/costAll/index.vue";
import countDouyinCount   from "./components/countDouyinCount/index.vue";
import { format, subDays } from "date-fns";
import md5 from "js-md5";
import {
  getUserBusinesslist,
  getDataStatistics,
  subPassword,
  getorderLanudryNum,
  getcountCouponNum,
  getcountCardNum,
  getcountCardCount,
  getlaundryCount,
  getcounthouerscarnum,
  getcountDouyinCount,
  getcostall

} from "@/api";
import { th, tr } from "date-fns/locale";
export default {
  name: "chart-data",
  components: {
    countToNum,
    countToNumOne,
    orderLanudryNum,
    countCouponNum,
    countCardNum,
    countTimeCardNum,
    countCardCount,
    countDouyinCount,
    costAll
  },
  data() {
    return {  
      business_id: {},
     'token':"",
      'bid':0,
      value: [
        format(subDays(new Date(), 7), "yyyy-MM-dd 00:00:00"),
        format(subDays(new Date(), 0), "yyyy-MM-dd 23:59:59")
      ],
      timePickerData: [
        format(subDays(new Date(), 7), "yyyy-MM-dd 00:00:00"),
        format(subDays(new Date(), 0), "yyyy-MM-dd 23:59:59")
      ],
      totimePickerData: [
        format(subDays(new Date(), 0), "yyyy-MM-dd 00:00:00"), 
        format(subDays(new Date(), 0), "yyyy-MM-dd 23:59:59")
      ],
      dataStatistics: {
        order_price:{},
        amount_price:{},  
        user_number:{},
        car_number:{},
        towel_number:{},
        douyincounp_number:{},
        coupon_number:{},
      },
      dataLaundry:{},
      shopUserPriceList: [],
      pickerOptions: {
        disabledDate(time) {
          return (
            new Date(format(time, "yyyy-MM-dd")).getTime() >=
            new Date(format(new Date(), "yyyy-MM-dd")).getTime()
          );
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date(format(new Date(), "yyyy-MM-dd"));
              const start = new Date(format(new Date(), "yyyy-MM-dd"));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 8);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date(format(new Date(), "yyyy-MM-dd"));
              const start = new Date(format(new Date(), "yyyy-MM-dd"));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 31);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date(format(new Date(), "yyyy-MM-dd"));
              const start = new Date(format(new Date(), "yyyy-MM-dd"));
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 91);
              end.setTime(end.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      dialogVisible: true,
      password: "",
      username: "",
      LOGIN: 0,
      laundryOrderBid: "",
      TodataPrice: "",
      laundryAmountBid: "",
      options: []
    };
  },
  created() {
    this.LOGIN = sessionStorage.getItem("LOGIN");
    this.token = sessionStorage.getItem('token');
    if (this.LOGIN) {
      this.dialogVisible = !this.LOGIN;
      this.init();
    }
  },
  computed: {},
  mounted() {
    //this.getStoreList();
    
    // this.init();
  },
  methods: {
    handleClose(done) {
      return;
    },
    async handleSubmit() {
      try {
        const _response = await subPassword({ username:this.username,pwd: this.password});
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }
        sessionStorage.setItem("LOGIN", 1);
        sessionStorage.setItem('token',data.token);
       // const params = await this.getParamsTime();
        this.init();
        this.$message.success(msg);
        this.dialogVisible = false;
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    handlePickerSubmit() {
      this.timePickerData = this.value;
      this.init();
    },
    async init() {
      const params = await this.getParamsTime();
      this.getUserBusinesslist(params);
      this.getDataStatistics(params);
      this.getorderLanudryNum(params);
      this.getcountCouponNum(params);
      this.getcountCardNum(params);
      this.getcounthouerscarnum(params);
      this.getcountCardCount(params);
      this.getDataLaundry(params);
      this.getcountDouyinCount(params);
      this.getcostall(params);
      // this.getOrderQuantity(params);
  
    },
    async getParamsTime() {
      let params = {};
      if (this.value[0] instanceof Date && this.value[1] instanceof Date) {
        params.startTime = this.value[0].getTime() / 1000;
        params.endTime = this.value[1].getTime() / 1000;
        params.bid = this.bid;
      } else {
        params.startTime = new Date(this.value[0]).getTime() / 1000;
        params.endTime = new Date(this.value[1]).getTime() / 1000;
        params.bid = this.bid;
      }
      params.token =  sessionStorage.getItem('token');
      return Promise.resolve(params);
    },
    //获取基础数据
    async getDataStatistics(params) {
      try {
        const _response = await getDataStatistics(params);
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);

          return;
        }
        this.dataStatistics = data;
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getUserBusinesslist(params){
      try {
        const _response = await getUserBusinesslist(params); 
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          this.dialogVisible =  true;
          return;
        }
        this.business_id = data.data;
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getorderLanudryNum(params) {
      
      try {
        const _response = await getorderLanudryNum(params); 
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }

        this.$refs["orderLanudryNum"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getcountCouponNum(params) {
      try {
        const _response = await getcountCouponNum(params);
        const { data, error, msg } = _response;  
        if (error !== 0) { 
          this.$message.error(msg);
          return;  
        }
        this.$refs["countCouponNum"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getcountDouyinCount(params){
      try {
        const _response = await getcountDouyinCount(params);
        const { data, error, msg } = _response;  
        if (error !== 0) { 
          this.$message.error(msg);
          return;  
        }
        this.$refs["countDouyinCount"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getcountCardNum(params) {      
      try {
        const _response = await getcountCardNum(params);
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }
        this.$refs["countCardNum"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getcounthouerscarnum(params) {      
      try {
        const _response = await getcounthouerscarnum(params);
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }
        this.$refs["countTimeCardNum"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    
    async getcountCardCount(params) {      
      try {
        const _response = await getcountCardCount(params);
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }
        this.$refs["countCardCount"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    async getcostall(params) {      
      try {
        const _response = await getcostall(params);
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }
        this.$refs["costAll"].initPieData(data.list, data);
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
    //获取基础数据
    async getDataLaundry(params) {
      try {
        const _response = await getlaundryCount(params);
        const { data, error, msg } = _response;
        if (error !== 0) {
          this.$message.error(msg);
          return;
        }
        this.dataLaundry = data;
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(e)));
      }
    },
  }
};
</script>
<style scoped lang="scss">
.chart-container {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
}
.chart-filter-container {
  display: flex;
  justify-content: space-between;
  .chart-filter-text {
    color: #6a788a;
  }
  .el-button {
    margin-left: 10px;
  }
}
.el-row-item {
  margin-top: 20px;
}
.count-item-container {
  background: #fff;
  border-radius: 4px;
  height: 200px;
}
.chart-item-container,
.table-container {
  background: #fff;
  height: 420px;
  box-sizing: border-box;
  padding: 20px;
}
.count-item-container_padding {
  padding-left: 0 !important;
}
.count-item-container_padding-right {
  padding-right: 0 !important;
}
.count-item-container_margin {
  margin-top: 20px;
}
.count-container {
  background: #fff;
  height: 200px;
}
.count-container_margin {
  margin: 20px 0;
}
</style>
