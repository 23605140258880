import axios from "@/libs/util.request";


export const getDataStatistics = params => {
  return axios.post(`bigdata.bigindex/home`, params);
};
// 获取门店列表
export const getUserBusinesslist = params =>{
  return axios.post(`bigdata.bigindex/bussinesslist`,params);
};
export const getOrderPrice = params => {
  return axios.post(`bigdata.bigindex/orderbig`, params);
};
export const subPassword = params => {
  return axios.post(`bigdata.bigindex/checkPwd`, params);
};
export const getorderLanudryNum = params => {
  return axios.post(`bigdata.bigindex/todata`, params);
};
export const getcountCouponNum = params => {
  return axios.post(`bigdata.bigindex/countcoupon`, params);
};
export const getcountCardNum = params => {
  return axios.post(`bigdata.bigindex/countcard`, params);
};
export const getcountCardCount = params =>{
  return axios.post(`bigdata.bigindex/countcardcount`, params);
};
export const getlaundryCount = params => {
  return axios.post(`bigdata.bigindex/countlaundrybusines`, params);
};
export const getcounthouerscarnum = params => {
  return axios.post(`bigdata.bigindex/counthouerscarnum`, params);
};
export const getcountDouyinCount = params =>{
  return axios.post(`bigdata.bigindex/countcoupondouyin`, params);
}
export const getcostall = params =>{
  return axios.post(`bigdata.bigindex/getcostall`, params);
}



// export const getOrderQuantity = params => {
//   return axios.post(`bigdata.bigindex/orderQuantityStatistics`, params);
// };

// export const getLaundryAmount = params => {
//   return axios.post(`bigdata.bigindex/laundryOrderAmount`, params);
// };

// export const getLaundryOrder = params => {
//   return axios.post(`bigdata.bigindex/laundryOrderNum`, params);
// };

// export const getCardNum = params => {
//   return axios.post(`bigdata.bigindex/storeCardNum`, params);
// };

// export const getStoreTotal = params => {
//   return axios.post(`bigdata.bigindex/storeStatistics`, params);
// };



// export const getStoreList = params => {
//   return axios.post(`bigdata.bigindex/getStoreList`, params);
// };

// export const getStoreSales = params => {
//   return axios.post(`bigdata.bigindex/toDayStoreSale`, params);
// };

// export const getStoreLaundryNum = params => {
//   return axios.post(`bigdata.bigindex/storeOrderGoods`, params);
// };

// export const getShopUserPrice = params => {
//   return axios.post(`bigdata.bigindex/shop_user_price`, params);
// };
